import React, { useEffect, useState } from "react";
import * as Utils from "../../features/Utils";
import { TOURINFO_COLOR_SET_IN } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { ShowInfo, UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPManager from '../../features/HTTPManager';
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import images from '../../assets/images';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import "../styles/CommonStyle.css";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TourInfoItemGuide = ({ tour: tourInfo, guide: toursGuide, guideName, hotel, bus, hotelList, busList, index, refresh, guideMemoList }) => {
  const navigate = useNavigate();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const languageSet = useRecoilValue(LanguageSet);

  // 색상정보
  let colorSet = setColor(tourInfo.nameCountry);
  let dday = checkExistTour(tourInfo.startDate, tourInfo.endDate) ? getPlusDday(tourInfo.startDate) + "일차" : (checkCompleteTour(tourInfo.endDate) ? "완료" : "D-" + getMinusDday(tourInfo.startDate));

  // 전달 받은 정보
  let tourHotelInfo = hotel;
  let tourBusInfo = bus;
  let tourGuideInfo = toursGuide?.find(item => (item.guideId === tourInfo.guideId && item.tourId === tourInfo.tourId));

  // 호텔정보
  let hotelTmpArray = tourHotelInfo?.filter(hotelInfo => hotelInfo.tourId === tourInfo.tourId).map(item => { return item.hotelId });
  let uniqueHotelList = [...new Set(hotelTmpArray)];
  let filterToursHotel = tourHotelInfo?.filter(hotelInfo => hotelInfo.tourId === tourInfo.tourId); // 하나의 행사에 가이드 2명인 경우 겹침

  // 버스정보
  let busTmpArray = tourBusInfo?.filter(busInfo => busInfo.tourId === tourInfo.tourId).map(item => { return item.busId });
  let uniquebusList = [...new Set(busTmpArray)];
  let filterToursBus = tourBusInfo?.filter(busInfo => busInfo.tourId === tourInfo.tourId); // 하나의 행사에 가이드 2명인 경우 겹침

  const showInfo = useRecoilValue(ShowInfo);
  const userInfo = useRecoilValue(UserInfo);

  const [showDialog, setShowDialog] = useState(false);
  const [enterPax, setEnterPax] = useState("");

  const [showMemoDialog, setShowMemoDialog] = useState(false);
  const [guideMemo, setGuideMemo] = useState("");

  const [showReceiptList, setShowReceiptList] = useState(false);
  const [receiptList, setReceiptList] = useState([]);

  async function fetchData() {
    setReceiptList([]);
    let res = await HTTPSettlementManager.getReceiptFileList({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.tourId,
    });

    if (res.data !== undefined && res.data !== null && res.data.result?.length > 0) {
      setReceiptList(res.data.result);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fileUpload = async (target, tourInfo, toursGuideId, type) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        if (type === "settlement") {
          let res = await HTTPSettlementManager.updateSettlementFile({
            accessToken: userInfo.accessToken,
            tourId: tourInfo.tourId,
            toursGuideId: toursGuideId,
            fileList: [
              {
                type: type,
                file: file,
              },
            ],
          });

          if (res?.data?.result) {
            await refresh();
            toast("정산서 업로드 완료", { type: "success", closeButton: false });
          } else {
            toast("정산서 업로드 실패", { type: "warning", closeButton: false });
          }
        } else if (type === "receipt") {
          let res = await HTTPSettlementManager.updateReceiptFile({
            accessToken: userInfo.accessToken,
            tourId: tourInfo.tourId,
            toursGuideId: toursGuideId,
            fileList: [
              {
                type: type,
                file: file,
              },
            ],
          });

          if (res?.data?.result) {
            await refresh();
            toast("영수증 업로드 완료", { type: "success", closeButton: false });
          } else {
            toast("영수증 업로드 실패", { type: "warning", closeButton: false });
          }
        }
      }
    }
  };

  // 함수
  function setColor(country) {
    switch (country) {
      case "인도네시아":
        return TOURINFO_COLOR_SET_IN.IN;
      case "필리핀":
        return TOURINFO_COLOR_SET_IN.PH;
      default:
        return TOURINFO_COLOR_SET_IN.IN;
    }
  }

  function checkExistTour(startDate, endDate) {
    if (today >= new Date(startDate.replace(" ", "T")) && today <= new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function checkCompleteTour(endDate) {
    if (today > new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function getMinusDday(startDate) {
    var dayCount = (new Date(startDate.replace(" ", "T")) - today) / 86400000;

    return dayCount;
  }

  function getPlusDday(startDate) {
    var dayCount = (today - new Date(startDate.replace(" ", "T"))) / 86400000 + 1;

    return dayCount;
  }

  function getWithDate(value) {
    if (value === undefined || value === null) {
      return "";
    }

    let newDate = new Date(value);

    let dayStr = "";
    switch (newDate.getDay()) {
      case 0:
        dayStr = "일";
        break;
      case 1:
        dayStr = "월";
        break;
      case 2:
        dayStr = "화";
        break;
      case 3:
        dayStr = "수";
        break;
      case 4:
        dayStr = "목";
        break;
      case 5:
        dayStr = "금";
        break;
      case 6:
        dayStr = "토";
        break;
    }

    return `${value.slice(0, 10)}(${dayStr})`.replaceAll('-', '.');
  }

  async function requestModify() {
    if (enterPax.length === 0) return;

    let res = await HTTPManager.ModifyTourEnterPaxInfo({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.tourId,
      param: {
        enterPax: enterPax,
      },
    });

    if (res?.data?.result) {
      setEnterPax("");
      setShowDialog(false);
      refresh();
    } else {
      alert("수정 실패!");
    }
  }

  async function insertGuideMemo() {
    if (guideMemo.length === 0) return;

    let result = await HTTPManager.InsertGuideMemo({
      accessToken: userInfo.accessToken,
      param: {
        tourId: tourInfo.tourId,
        contents: guideMemo,
        type: 1,
      }
    });

    if (result.data !== undefined && result.data !== null) {
      if (result.data?.result === true) {
        setGuideMemo("");
        setShowMemoDialog(false);
        refresh();
      }
    }
  }

  const marginBottomContent = `mb-[20px]`;
  const clearEnterPax = tourInfo.enterPax !== null && tourInfo.enterPax !== undefined;

  let style = "mx-2 rounded-lg bg-[#eee]";

  function getAirplaneLOGO(flight) {
    let res = [];

    if (flight?.includes("KE")) {
      res.push(<img src={images.KOREANAIR} className={`${style}`} key="KE" />);
    } else if (flight?.includes("OZ")) {
      res.push(<img src={images.ASIANA} className={`${style}`} key="OZ" />);
    } else if (flight?.includes("SQ")) {
      res.push(<img src={images.SIN} className={`${style}`} key="SQ" />);
    } else if (flight?.includes("PR")) {
      res.push(<img src={images.PAL} className={`${style}`} key="PR" />);
    } else if (flight?.includes("GA")) {
      res.push(<img src={images.GA} className={`${style}`} key="GA" />);
    } else if (flight?.includes("Z2")) {
      res.push(<img src={images.AIRASIA} className={`${style}`} key="Z2" />);
    } else if (flight?.includes("D7")) {
      res.push(<img src={images.AIRASIAX} className={`${style}`} key="D7" />);
    } else if (flight?.includes("5J")) {
      res.push(<img src={images.CEBU} className={`${style}`} key="5J" />);
    } else if (flight?.includes("CX")) {
      res.push(<img src={images.CPA} className={`${style}`} key="CX" />);
    } else if (flight?.includes("VN")) {
      res.push(<img src={images.VN} className={`${style}`} key="VN" />);
    } else if (flight?.includes("MH")) {
      res.push(<img src={images.MH} className={`${style}`} key="MH" />);
    } else if (flight?.includes("BI")) {
      res.push(<img src={images.BI} className={`${style}`} key="BI" />);
    } else if (flight?.includes("OD")) {
      res.push(<img src={images.OD} className={`${style}`} key="OD" />);
    } else if (flight?.includes("TR")) {
      res.push(<img src={images.TR} className={`${style}`} key="TR" />);
    } else if (flight?.includes("TG")) {
      res.push(<img src={images.TG} className={`${style}`} key="TG" />);
    } else if (flight?.includes("MU")) {
      res.push(<img src={images.MU} className={`${style}`} key="MU" />);
    } else if (flight?.includes("NX")) {
      res.push(<img src={images.NX} className={`${style}`} key="NX" />);
    } else if (flight?.includes("CZ")) {
      res.push(<img src={images.CZ} className={`${style}`} key="CZ" />);
    } else if (flight?.includes("MF")) {
      res.push(<img src={images.MF} className={`${style}`} key="MF" />);
    }

    return res;
  }

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] rounded-lg p-4 md:p-1">
      <div className="flex flex-row md:flex-col md:gap-2 mb-[10px] font-bold text-[18px] justify-between">
        <div>[{dday}] {index + 1}번 행사정보</div>
        <div className="scrollRemoveBox flex flex-row gap-1">
          {
            <div className={`flex-none bg-[crimson] rounded text-white cursor-pointer px-2 py-1 text-[14px] focus:outline-none hover:opacity-40 font-bold`}
              onClick={async () => {
                await fetchData();
                setShowReceiptList(true);
              }}
            >
              영수증 확인
            </div>
          }
          {
            (tourGuideInfo?.settlementURL !== null && tourGuideInfo?.settlementURL !== undefined) &&
            <div className={`flex-none bg-[crimson] rounded text-white cursor-pointer px-2 py-1 text-[14px] focus:outline-none hover:opacity-40 font-bold`}
              onClick={() => {
                Utils.showContent(tourGuideInfo?.settlementURL);
              }}
            >
              정산서 확인
            </div>
          }
          <div className={`flex-none bg-[#19CC95] rounded text-white cursor-pointer px-2 py-1 text-[14px] focus:outline-none hover:opacity-40 font-bold`}>
            <input
              className="hidden"
              id={`receipt-file-${index + 1}`}
              type="file"
              onChange={async (e) => {
                await fileUpload(e.target, tourInfo, tourGuideInfo?.id, "receipt");
              }}
              onClick={(event) => {
                // 동일한 파일 업로드 시도 하기 위한 필수 설정
                event.target.value = null
              }}
            />
            <label htmlFor={`receipt-file-${index + 1}`} className="cursor-pointer">
              <div>추가영수증 업로드</div>
            </label>
          </div>

          <div className={`flex-none bg-[#19CC95] rounded text-white cursor-pointer px-2 py-1 text-[14px] focus:outline-none hover:opacity-40 font-bold`}>
            <input
              className="hidden"
              id={`settlement-file-${index + 1}`}
              type="file"
              onChange={async (e) => {
                await fileUpload(e.target, tourInfo, tourGuideInfo?.id, "settlement");
              }}
              onClick={(event) => {
                // 동일한 파일 업로드 시도 하기 위한 필수 설정
                event.target.value = null
              }}
            />
            <label htmlFor={`settlement-file-${index + 1}`} className="cursor-pointer">
              <div>정산서 업로드</div>
            </label>
          </div>

          <button className={`flex-none bg-[#19CC95] rounded text-white cursor-pointer px-2 py-1 text-[14px] focus:outline-none hover:opacity-40 font-bold`}
            onClick={() => {
              setShowMemoDialog(true);
            }}
          >특이사항 입력</button>
        </div>
      </div>
      <div className="flex w-full h-[2px] bg-[#111111] mb-[20px]" />

      <div className="flex flex-row mb-[20px] text-[15px]">
        <div className="font-bold mr-[50px]">손님국적</div>
        <div>{tourInfo.nameCountry}</div>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="scrollRemoveBox flex flex-row mb-[20px] text-[15px]">
        <div className="flex-none font-bold mr-[50px]">행사기간</div>
        <div>{getWithDate(tourInfo.startDate)} ~ {getWithDate(tourInfo.endDate)}</div>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="scrollRemoveBox flex flex-row md:flex-row mb-[20px] text-[15px] items-center overflow-auto">
        <div className="flex-none font-bold mr-[40px] text-start">항공여정</div>
        <div className="flex flex-row md:flex-col md:gap-2 md:justify-start">
          <div className="scrollRemoveBox flex flex-row items-center overflow-auto">
            <div className="flex-none">
              {
                getAirplaneLOGO(tourInfo.eta)
              }
            </div>
            <div className="flex-none mr-5"> 입국 : {tourInfo.eta}</div>
          </div>
          <div className="scrollRemoveBox flex flex-row items-center overflow-auto">
            <div className="flex-none">
              {
                getAirplaneLOGO(tourInfo.etd)
              }
            </div>
            <div className="flex-none"> 출국 : {tourInfo.etd}</div>
          </div>
        </div>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="scrollRemoveBox flex flex-row mb-[20px] text-[15px] items-center">
        <div className="flex-none font-bold mr-[50px]">행사인원</div>
        <div className={`flex-none mr-[10px] ${(clearEnterPax && "line-through")}`}>{tourInfo.pax}</div>
        {
          clearEnterPax && <div className="flex-none mr-[10px] font-bold">{"입국 :"}</div>
        }
        {
          clearEnterPax && <div className="flex-none mr-[10px] font-bold normal-case">{tourInfo.enterPax}</div>
        }
        <button className={`flex-none ${clearEnterPax ? "bg-[#19CC95]" : "bg-[crimson]"} rounded text-white cursor-pointer px-2 py-1 text-[14px] focus:outline-none hover:opacity-40 font-bold`}
          onClick={() => {
            setShowDialog(true);
          }}
        >{clearEnterPax ? "입국인원 수정" : "입국인원 입력"}</button>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="flex flex-row mb-[20px] text-[15px]">
        <div className="font-bold mr-[50px]">배정정보</div>
        <div>
          {
            guideName !== undefined ?
              <div className="">
                {guideName}
              </div>
              :
              <div className="flex mr-[5px] text-[#20191b] font-bold text-[16px] cursor-pointer"
              >
                {"가이드 미정"}
              </div>
          }
        </div>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="scrollRemoveBox flex flex-row mb-[20px] text-[15px] overflow-auto">
        <div className="flex-none font-bold mr-[50px]">호텔정보</div>
        <div>
          {
            uniqueHotelList.length > 0 ?
              uniqueHotelList.map((item) => {
                return (
                  <div key={item} className="scrollRemoveBox flex flex-col md:w-full">
                    {
                      filterToursHotel.filter(resHotel => resHotel.hotelId === item).map((resHotel, idx, row) => {
                        return (
                          <div key={idx} className="flex flex-row md:w-full md:flex-col cursor-pointer mb-1">
                            <div className="flex flex-row">
                              <div className="flex flex-row items-center cursor-pointer">
                                <div className="flex flex-row mr-[5px]">
                                  <div className="flex-none mr-1">
                                    {
                                      hotelList?.find(hotel => hotel.id === resHotel.hotelId)?.name
                                    }
                                  </div>
                                  <div className="flex-none  mr-[5px]">
                                    [{resHotel.roomType?.trimEnd()}]
                                  </div>
                                  <div className="flex-none">
                                    ({getWithDate(resHotel.startDate)} ~ {getWithDate(resHotel.endDate)})
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }) :
              <div className="mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer">
                {"미정"}
              </div>
          }
        </div>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="scrollRemoveBox flex flex-row mb-[20px] text-[15px] overflow-auto">
        <div className="flex-none font-bold mr-[50px]">버스정보</div>
        <div>
          {
            uniquebusList.length > 0 ?
              uniquebusList.map((item) => {
                return (
                  <div key={item} className="scrollRemoveBox flex flex-row mb-[2px] md:w-full text-[15px] overflow-auto">
                    {
                      filterToursBus.filter(resBus => resBus.busId === item).map((resBus, idx, row) => {
                        let busInfo = busList?.find(bus => bus.id === resBus.busId);
                        let busDriver = busInfo?.name === "글로벌컴퍼니" ? "/" + busInfo?.manager : "";

                        return (
                          <div key={idx} className="flex flex-row md:w-full md:flex-col cursor-pointer">
                            <div className="flex flex-row">
                              <div className="flex flex-row items-center cursor-pointer">
                                <div className="flex flex-row mr-[5px]">
                                  <div className="flex-none mr-1">
                                    {
                                      busInfo?.name + busDriver
                                    }
                                  </div>
                                </div>
                                <div className="flex-none mr-[5px]">
                                  {resBus.busType}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }) :
              <div className="mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer">
                {"미정"}
              </div>
          }
        </div>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="flex flex-row mb-[20px] text-[15px]">
        <div className="font-bold mr-[50px]">부가정보</div>
        <div className="flex flex-col gap-1">
          <div>
            <div className="normal-case cursor-pointer text-[blue] font-bold"
              onClick={() => {
                Utils.showContent(tourInfo.logo);
              }}>
              {
                tourInfo.logo !== undefined && tourInfo.logo !== null ? "LOGO." + tourInfo.logo?.split('.')[tourInfo.logo?.split('.').length - 1]
                  : ""
              }
            </div>
          </div>
          <div>
            <div className="normal-case cursor-pointer text-[blue] font-bold"
              onClick={() => {
                Utils.showContent(tourInfo.rooming);
              }}>
              {
                tourInfo.rooming !== undefined && tourInfo.rooming !== null ? "ROOMING." + tourInfo.rooming?.split('.')[tourInfo.rooming?.split('.').length - 1]
                  : ""
              }
            </div>
          </div>
          <div>
            <div
              className="normal-case cursor-pointer text-[blue] font-bold"
              onClick={() => {
                Utils.showContent(tourInfo.scheduleKO);
              }}>
              {
                tourInfo.scheduleKO !== undefined && tourInfo.scheduleKO !== null ? "행사지시서." + tourInfo.scheduleKO?.split('.')[tourInfo.scheduleKO?.split('.').length - 1]
                  : ""
              }
            </div>
          </div>
        </div>
      </div>
      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="flex flex-row mb-[20px] text-[15px]">
        <div className="font-bold mr-[50px]">면세정보</div>
        <div>
          {
            (tourInfo.dutyFreeShop !== undefined && tourInfo.dutyFreeShop !== null) ? tourInfo.dutyFreeShop :
              <div className="mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer">
                {"미정"}
              </div>
          }
        </div>
      </div>

      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="flex flex-row mb-[20px] text-[15px]">
        <div className="font-bold mr-[50px] text-[crimson]">전달사항</div>
        <div>
          {
            (guideMemoList?.length > 0) &&
            guideMemoList.filter(item => item.type === 0).map((memo, index) => {
              return (
                <div key={memo.id} className="mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer normal-case">
                  {memo.contents}
                </div>
              )
            })
          }
        </div>
      </div>

      <div className={`flex w-full h-[1px] bg-[#D3DBD9] ${marginBottomContent}`} />

      <div className="flex flex-row mb-[20px] text-[15px]">
        <div className="font-bold mr-[50px] text-[crimson]">특이사항</div>
        <div>
          {
            (guideMemoList?.length > 0) &&
            guideMemoList.filter(item => item.type === 1).map((memo, index) => {
              return (
                <div key={memo.id} className="mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer normal-case">
                  {memo.contents}
                </div>
              )
            })
          }
        </div>
      </div>
      {
        showReceiptList &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
            <div className="relative h-full w-full py-5">
              <div className="border-0 shadow-lg relative flex flex-col w-full h-full bg-[#dfdfdf] outline-none focus:outline-none normal-case">
                <span className={`absolute right-6 top-4 bg-[#dfdfdf]`}
                  style={{ fontSize: 20, cursor: "pointer" }}
                  onClick={() => {
                    setShowReceiptList(false);
                  }}>
                  <FontAwesomeIcon icon={faX} style={{ color: "#000", }} />
                </span>
                <div className="flex w-full justify-center mt-5">
                  TOTAL {receiptList?.length}
                </div>
                <div className="flex flex-col px-4">
                  {
                    receiptList.map((item, index) => {
                      let fileName = item.name;
                      let fileExt = Utils.mimeTypeToExt(item.ext);
                      let originalFileName = item.originalName;
                      let url = "https://ktravelstore.com:9786/images/kts/tsm/receipt/" + fileName + fileExt;

                      return (
                        <div key={item.id} className="flex flex-row gap-3 cursor-pointer p-2 hover:bg-[white]" onClick={() => {
                          if (originalFileName.endsWith(".jpeg") || originalFileName.endsWith(".jpg") || originalFileName.endsWith(".png") || originalFileName.endsWith(".pdf"))
                            window.open(url, "_blank");
                        }}>
                          <div>{index + 1}.</div>
                          <div>{originalFileName}</div>
                          <div>{item.createdAt}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        showDialog ? (
          <>
            <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium select-none">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-center p-5 rounded-t">
                    <h2 className="text-base">입국인원</h2>
                  </div>
                  <div className="relative p-3 flex-auto">
                    <input
                      autoComplete="off"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                      placeholder={"입국인원"}
                      type="text"
                      name="text"
                      onChange={(e) => {
                        setEnterPax(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          requestModify();
                        }
                      }}
                      value={enterPax}
                    />
                  </div>

                  <div className="flex w-full items-center justify-center rounded-b border-t">
                    <button
                      className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                      type="button"
                      onClick={() => {
                        setShowDialog(false);
                      }}
                    >
                      {languageSet.pwChangeClose}
                    </button>
                    <button
                      className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                      type="button"
                      onClick={async () => {
                        requestModify();
                      }}
                    >
                      {"입력"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null
      }
      {
        showMemoDialog ? (
          <>
            <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium select-none">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-center p-5 rounded-t">
                    <h2 className="text-base">특이사항</h2>
                  </div>
                  <div className="relative p-3 flex-auto">
                    <input
                      autoComplete="off"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                      placeholder={"내용입력"}
                      type="text"
                      name="text"
                      onChange={(e) => {
                        setGuideMemo(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          insertGuideMemo();
                        }
                      }}
                      value={guideMemo}
                    />
                  </div>

                  <div className="flex w-full items-center justify-center rounded-b border-t">
                    <button
                      className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                      type="button"
                      onClick={() => {
                        setShowMemoDialog(false);
                      }}
                    >
                      {languageSet.pwChangeClose}
                    </button>
                    <button
                      className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                      type="button"
                      onClick={async () => {
                        insertGuideMemo();
                      }}
                    >
                      {"입력"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null
      }

    </div >
  );
};

export default TourInfoItemGuide;
