import React, { useEffect, useState, useMemo, useRef } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPManager from "../../features/HTTPManager";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../styles/CommonStyle.css";
import FundItem from "./FundItem";

export default function FundWindow() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);

  async function fetchData() {
    let res = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    let userList = [];
    let toursGuideInfo = [];
    let toursFunds = [];

    if (res !== undefined) {
      userList = res.data.result;
    }

    res = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      toursGuideInfo = res.data.toursGuide;
      toursFunds = res.data.toursFunds;
    }

    res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      let tmpList = res.data.result.sort((a, b) => new Date(a.startDate.replace(" ", "T")) - new Date(b.startDate.replace(" ", "T")))
      tmpList = tmpList.map(tour => {
        let guideId = toursGuideInfo.find(tourGuide => tourGuide.tourId === tour.id)?.guideId;
        let guideName = userList.find(user => user.id === guideId)?.name?.split('/')[0];

        let fundInfo = toursFunds.find(fund => fund.tourId === tour.id);

        return ({
          id: tour.id,
          country: getCountrName(tour.countryId),
          pax: tour.pax,
          docNum: tour.docNum,
          startDate: tour.startDate.slice(0, 10),
          endDate: tour.endDate.slice(0, 10),
          guide: guideName,
          cancel: tour.cancel,
          fundAmount: fundInfo?.amount,
          fundAccount: fundInfo?.account,
          funcReceipt: fundInfo?.receipt,
          fundState: fundInfo?.state,
          fundCity: fundInfo?.cityId,
          fundPicture: fundInfo?.picture,
          fundGroupType: fundInfo?.groupType,
          fundDepositDate: fundInfo?.depositDate,
        });
      });

      setTourList(tmpList);
      setTourFilterList(tmpList);
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  function getCountrName(value) {
    switch (value) {
      case 2:
        return "인도네시아";
      case 3:
        return "필리핀";
    }
  }

  const redTag = (msg) => {
    return <span
      className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-[5px] cursor-pointer"
    > {msg}</span >
  }

  const greenTag = (msg) => {
    return <span
      className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-[5px] cursor-pointer"
    >{msg}</span>
  }

  const yellowTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-[5px]">{msg}</span>
  }

  const outlineStyled = {
    "textTransform": "none",
    "width": "100%",
    "--md-outlined-text-field-container-shape": "0px",
    "--md-outlined-text-field-focus-outline-width": "2px",
    "--md-outlined-text-field-focus-outline-color": "#B3261E",
    "--md-outlined-text-field-outline-color": "#000000",
    "--md-outlined-text-field-label-text-color": "#ababab",
    "--md-outlined-text-field-label-text-size": "13px",
    "--md-outlined-text-field-focus-label-text-color": "#B3261E",
  }

  return (
    <div className={`w-full h-full`}>
      <div className="w-full h-full md:mt-14">
        <div className="scrollRemoveBox flex p-1">
        </div>

        <div className="flex flex-col w-full h-full gap-1 px-2">
          <div className="flex flex-col w-full overflow-auto gap-2">
            {
              tourFilterList.filter(tour => tour.fundState !== undefined)?.map((tour, index) => {
                return (<FundItem key={tour.id} tour={tour} index={index} />)
              })
            }
          </div>
          <div className="flex flex-row mt-3 font-bold text-[20px] w-full justify-center">
            <div>
              {
                "총 " + Utils.formatNumber(tourFilterList.filter(tour => tour.fundState !== undefined)?.reduce((a, b) => a = a + Number(b.fundAmount), 0)) + "원"
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}