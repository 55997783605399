import axios from "axios";
import { ERRORCODE } from "../features/Constant";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UserInfo } from "../recoil";
import { useNavigate } from "react-router-dom";

const PRODUCTION = process.env.NODE_ENV === "development" ? false : true;

const SERVER_URL = PRODUCTION
  ? "https://ktravelstore.com:1225"
  : "http://localhost:1225";

const instance = axios.create({
  baseURL: SERVER_URL,
  withCredentials: true,
});

export const useAxiosInterceptor = () => {
  const setMyInfo = useSetRecoilState(UserInfo);
  const navigate = useNavigate();
  const [needToLogin, setNeedToLogin] = useState(false);

  const errorHandler = (error) => {
    //let msg = error.data.message;
    let code = error.data.code;

    switch (code) {
      // refresh 토큰까지 만료된 경우 > 재로그인 요청
      case ERRORCODE.NEED_TO_LOGIN.CODE:
        setNeedToLogin(true);
        setMyInfo({});
        break;
      default:
        break;
    }

    return error;
  };

  const responseHandler = (response) => {
    return response;
  };

  const responseInterceptor = instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error.response)
  );

  useEffect(() => {
    // 로그인 만료 처리
    if (needToLogin) {
      setNeedToLogin(false);
      navigate("/", { replace: true });
    }

    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [responseInterceptor, needToLogin]);
};

export async function getAgencyAccountList(Props) {
  return await instance
    .get("/settlement/agency/", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function insertRequest(Props) {
  return await instance
    .post("/settlement/agency/", {
      title: Props.title,
      content: Props.content,
      period: Props.period,
      agent: Props.agent,
      flight: Props.flight,
      itinerary: Props.itinerary,
      pax: Props.pax,
      hotelStarRating: Props.hotelStarRating,
      schedule: Props.schedule,
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}


// 팁 지급 파일 업로드
export async function updateTipFile(Props) {
  const formData = new FormData();

  // 파일 리스트 업로드
  for (let i = 0; i < Props.fileList?.length; i++) {
    let item = Props.fileList[i];
    formData.append(item.type, item.file);
  }

  if (Props.toursGuideId !== undefined && Props.toursGuideId !== null)
    formData.append("toursGuideId", Props.toursGuideId);

  return await instance
    .put("/settlement/tip/" + Props.tourId, formData, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 파일 업로드
export async function updateSettlementFile(Props) {
  const formData = new FormData();

  // 파일 리스트 업로드
  for (let i = 0; i < Props.fileList?.length; i++) {
    let item = Props.fileList[i];
    formData.append(item.type, item.file);
  }

  if (Props.toursGuideId !== undefined && Props.toursGuideId !== null)
    formData.append("toursGuideId", Props.toursGuideId);

  return await instance
    .put("/settlement/guide/file/" + Props.tourId, formData, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 추가 인센티브 입력
export async function insertAdditionalIncentive(Props) {
  return await instance
    .post("/settlement/guide/incentive/" + Props.toursGuideId, {
      dataType: Props.dataType,
      dataValue: Props.dataValue,
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 추가 인센티브 삭제
export async function removeAdditionalIncentive(Props) {
  return await instance
    .delete("/settlement/guide/incentive/" + Props.id, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 추가 인센티브 목록 조회
export async function getAdditionalIncentiveList(Props) {
  return await instance
    .get("/settlement/guide/incentive", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 비고 입력
export async function insertETC(Props) {
  return await instance
    .post("/settlement/guide/memo/" + Props.toursGuideId, {
      dataValue: Props.dataValue,
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 비고 삭제
export async function removeETC(Props) {
  return await instance
    .delete("/settlement/guide/memo/" + Props.id, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 비고 조회
export async function getETCList(Props) {
  return await instance
    .get("/settlement/guide/memo", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 데이터 업데이트
export async function updateSettlementData(Props) {
  return await instance
    .put("/settlement/guide/" + Props.tourId, {
      toursGuideId: Props.toursGuideId,
      dataType: Props.dataType,
      dataValue: Props.dataValue,
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 리스트 데이터 업데이트
export async function updateSettlementListData(Props) {
  return await instance
    .put("/settlement/guide/", {
      dataType: Props.dataType,
      dataValue: Props.dataValue,
      dataList: Props.dataList,
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 영수증 파일 목록
export async function getReceiptFileList(Props) {
  return await instance
    .get("/settlement/receipt/" + Props.tourId, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 영수증 파일 업로드
export async function updateReceiptFile(Props) {
  const formData = new FormData();

  // 파일 리스트 업로드
  for (let i = 0; i < Props.fileList?.length; i++) {
    let item = Props.fileList[i];
    formData.append(item.type, item.file);
  }

  if (Props.toursGuideId !== undefined && Props.toursGuideId !== null)
    formData.append("toursGuideId", Props.toursGuideId);

  return await instance
    .put("/settlement/receipt/" + Props.tourId, formData, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}