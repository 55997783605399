import PageMain from "./infoMenu/PageMain";
import CalculateIncome from "./infoMenu/CalculateIncome";
import ScheduleList from "./ScheduleList";
import TourInfoListMainPage from "./tourInfoList/MainPage";
import SignUp from "./users/SignUp";
import BusList from "./BusList";
import HotelList from "./HotelList";
import RegistGuide from "./RegistGuide";
import RegisterUser from "../components/users/RegisterUser";
import RoomingWindow from "./rooming/RoomingWindow";
import RequestWindow from "./requestBoard/RequestWindow";
import LogWindow from "./logging/LogWindow";
import InvoiceWindow from "./management/InvoiceWindow";
import FinishPage from "./hanmaru/FinishPage";
import FundWindow from "./fund/FundWindow";
import AccountPage from "./hanmaru/AccountPage";
import AgencyAccountWindow from "./management/AgencyAccountWindow";
import BusCalendar from "./bus/BusCalendar";
import SettlementPage from "./management/SettlementPage";
import GuideSettlementPage from "./infoMenu/GuideSettlementPage";

const menuItems = [
  {
    name: "정보",
    key: "menuInfo",
    path: "",
    element: "",
    type: "guide",
    subMenu: [
      {
        name: "일정",
        key: "menuInfoSchedule",
        path: "/guideSchedule",
        element: <PageMain />,
        type: "guide",
      },
      {
        name: "정산",
        key: "menuInfoGuideSettlement",
        path: "/guideSettlement",
        element: <GuideSettlementPage />,
        type: "guide",
      },
    ],
  },
  {
    name: "투어",
    key: "menuTour",
    path: "",
    element: "",
    type: "its",
    subMenu: [
      // {
      //   name: "달력",
      //   key: "menuTourCalendar",
      //   path: "/scheduleCalendar",
      //   element: <ScheduleCalendar />,
      //   type: "admin",
      // },
      {
        name: "목록",
        key: "menuTourList",
        path: "/scheduleList",
        element: <ScheduleList />,
        type: "admin",
      },
      {
        name: "목록(표)",
        key: "menuTourListEx",
        path: "/tourInfoMain",
        element: <TourInfoListMainPage />,
        type: "admin",
      },
      {
        name: "루밍",
        key: "menuTourRooming",
        path: "/rooming",
        element: <RoomingWindow />,
        type: "its",
      },
      {
        name: "요청",
        key: "menuTourRequest",
        path: "/itsRequest",
        element: <RequestWindow />,
        type: "its",
      },
      // {
      //   name: "통계",
      //   key: "menuTourStatistics",
      //   path: "/statistics",
      //   element: <StatisticsWindow />,
      //   type: "admin",
      // },
      {
        name: "지원금",
        path: "/fund",
        element: <FundWindow />,
        type: "god",
      },
    ],
  },
  {
    name: "사용자",
    key: "menuUser",
    path: "",
    element: "",
    type: "admin",
    subMenu: [
      {
        name: "가이드",
        key: "menuGuide",
        path: "/registGuide",
        element: <RegistGuide />,
        type: "admin",
      },
      {
        name: "관리자",
        key: "menuManager",
        path: "/registManager",
        element: <RegisterUser />,
        type: "admin",
      },
    ],
  },
  {
    name: "버스",
    key: "menuBus",
    path: "",
    element: "",
    type: "admin",
    subMenu: [
      {
        name: "등록 및 목록",
        key: "menuBusRegistNList",
        path: "/bus",
        element: <BusList />,
        type: "admin",
      },
      {
        name: "달력",
        key: "menuBusCalendar",
        path: "/busCalendar",
        element: <BusCalendar />,
        type: "god",
      },
    ],
  },
  {
    name: "호텔",
    key: "menuHotel",
    path: "",
    element: "",
    type: "admin",
    subMenu: [
      {
        name: "호텔 등록 및 목록",
        key: "menuHotelRegistNList",
        path: "/hotel",
        element: <HotelList />,
        type: "admin",
      },
    ],
  },
  // {
  //   name: "관광지",
  //   key: "menuAttraction",
  //   path: "",
  //   element: "",
  //   type: "admin",
  //   subMenu: [
  //     {
  //       name: "등록 및 목록",
  //       key: "menuAttractionList",
  //       path: "/attraction",
  //       element: <AttractionList />,
  //       type: "admin",
  //     },
  //   ],
  // },
  // {
  //   name: "커뮤니티",
  //   key: "menuCommunity",
  //   path: "",
  //   element: <Community />,
  //   type: "side",
  //   subMenu: [
  //     {
  //       name: "미니게임",
  //       key: "menuCommunityMiniGame",
  //       path: "/minigame",
  //       element: <HeroesOfHammerWatch />,
  //       type: "admin",
  //     },
  //     {
  //       name: "오늘 뭐 먹지",
  //       key: "menuCommunityLunch",
  //       path: "/lunch",
  //       element: <TodayLunch />,
  //       type: "admin",
  //     },
  //   ],
  // },
  {
    name: "회원가입",
    path: "/signUp",
    element: <SignUp />,
    type: "extra",
  },
  // {
  //   name: "관리자설정",
  //   key: "menuManagerSetting",
  //   path: "/adminSetting",
  //   element: <AdminSetting />,
  //   type: "admin",
  // },
  {
    name: "로그",
    key: "menuLog",
    path: "/log",
    element: <LogWindow />,
    type: "admin",
    subMenu: [

    ],
  },
  {
    name: "매출",
    key: "menuManagement",
    path: "",
    element: "",
    type: "admin",
    subMenu: [{
      name: "매출",
      key: "menuManagementIncome",
      path: "/calculateEx",
      element: <CalculateIncome />,
      type: "god",
    },
    {
      name: "정산",
      key: "menuManagementSettlement",
      path: "/settlement",
      element: <SettlementPage />,
      type: "admin",
    },
    // {
    //   name: "손익",
    //   key: "menuManagementProfitAndLoss",
    //   path: "/profitAndLoss",
    //   element: <PageProfitAndLoss />,
    //   type: "god",
    // },
    {
      name: "인보이스",
      key: "menuManagementInvoice",
      path: "/invoice",
      element: <InvoiceWindow />,
      type: "admin",
    },
    {
      name: "에이전시별",
      key: "menuManagementAgencyAccount",
      path: "/agencyAccount",
      element: <AgencyAccountWindow />,
      type: "god",
    },]
  },
  {
    name: "한마루",
    key: "menuHanmaru",
    path: "",
    element: "",
    type: "god",
    subMenu: [
      {
        name: "매입 매출",
        key: "menuHanmaruAccount",
        path: "/hanmaru/account",
        element: <AccountPage />,
        type: "god",
      },
      {
        name: "마감",
        key: "menuHanmaruFinish",
        path: "/hanmaru/finish",
        element: <FinishPage />,
        type: "god",
      },]
  },
];

export default menuItems;
