import React, { useState, useEffect, forwardRef } from "react";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import * as HTTPRequestManager from "../../features/HTTPRequestManager";
import * as Utils from '../../features/Utils';
import { REQUEST_STATE, ROLES } from "../../features/Constant";
import RequestCommentItem from "./RequestCommentItem";
import DragDropFile from "../DragDropFile";
import RequestBoardHotelPriceDialog from "./RequestBoardHotelPriceDialog";

const styles = {
  registerButton: {
    fontWeight: "bold",
    outline: "none",
    background: "#000",
    color: "#fff",
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 7,
    paddingLeft: 7,
  },
};

export default function RequestBoardContent(Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);

  const [requestInfo, setRequestInfo] = useState("");
  const [period, setPeriod] = useState("");
  const [agent, setAgent] = useState("");
  const [flight, setFlight] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [pax, setPax] = useState("");
  const [hotelStarRating, setHotelStarRating] = useState("");
  const [schedule, setSchedule] = useState("");

  const [commentList, setCommentList] = useState([]);
  const [commentContent, setCommentContent] = useState("");

  const [hotelPriceList, setHotelPriceList] = useState([]);

  const userInfo = useRecoilValue(UserInfo);

  const [fileInfo, setFileInfo] = useState("");

  const [showInputHotelPrice, setShowInputHotelPrice] = useState(false);

  async function fetchData() {
    await getRequestInfo();
    await getCommentList();
    await getHotelPriceList();
  }

  async function getRequestInfo() {
    var response = await HTTPRequestManager.getRequestItem({
      accessToken: userInfo.accessToken,
      id: location.state.id,
    });

    if (response.status === 200) {
      if (response.data !== null && response.data.result !== null) {
        let requestInfo = response.data.result[0];
        setRequestInfo(requestInfo);
        setPeriod(requestInfo.period);
        setAgent(requestInfo.agent);
        setFlight(requestInfo.flightSchedule);
        setItinerary(requestInfo.itinerary);
        setPax(requestInfo.pax);
        setHotelStarRating(requestInfo.hotelStarRating);
        setSchedule(requestInfo.schedule);
      }
    }
  }

  async function getCommentList() {
    var response = await HTTPRequestManager.getRequestCommentList({
      accessToken: userInfo.accessToken,
      id: location.state.id,
    });

    if (response.status === 200) {
      if (response.data !== null && response.data.result !== null) {
        setCommentList(response.data.result);
      }
    }
  }

  async function getHotelPriceList() {
    var response = await HTTPRequestManager.getRequestHotelPriceList({
      accessToken: userInfo.accessToken,
      id: location.state.id,
    });

    if (response.status === 200) {
      if (response.data !== null && response.data.result !== null) {
        setHotelPriceList(response.data.result);
      }
    }
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await registerComment();
    }
  }

  async function registerComment(fileInfo) {
    var res = await HTTPRequestManager.insertRequestComment({
      accessToken: userInfo.accessToken,
      requestId: location.state.id,
      content: commentContent,
      file: fileInfo,
    });

    if (res.data.result) {
      if (fileInfo !== undefined && fileInfo !== "") {
        window.location.reload();
      } else {
        setCommentContent("");
        await getCommentList();
      }
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ITS, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
      });
  }, []);

  async function onButtonDeleteClick() {

  }

  let waitForResponse = false;

  const redTag = (msg, allowClick) => {
    let cursorStyle = allowClick ? "cursor-pointer" : "";

    return <span
      className={`inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-[5px] cursor-pointer ${cursorStyle}`}
      onClick={async () => {
        if (allowClick && waitForResponse === false) {
          waitForResponse = true;

          let answer = window.confirm(`[${requestInfo.id}]` + " \"" + location.state.title + "\" Remove?");
          if (answer) {
            // 삭제처리
            let response = await HTTPRequestManager.updateRequestList({
              accessToken: userInfo.accessToken,
              list: [requestInfo.id],
              state: 6,
            });

            if (response !== undefined && response.data !== undefined) {
              if (response.data?.result) {
                await fetchData();
                navigate(-1);
              }
            }
          }

          waitForResponse = false;
        } else {
          alert('Wait For Update!!');
        }
      }}
    > {msg}</span >
  }

  const checkITSTag = (msg, allowClick) => {
    let cursorStyle = allowClick ? "cursor-pointer" : "";

    return <span
      className={`select-none inline-flex items-center rounded-md bg-[red] px-2 py-1 text-xs font-bold text-white ring-1 ring-inset ring-red-600/20 mr-[5px] ${cursorStyle}`}
      onClick={async (e) => {
        if (allowClick && waitForResponse === false) {
          waitForResponse = true;

          let res = await HTTPRequestManager.updateRequestState({
            accessToken: userInfo.accessToken,
            requestId: location.state.id,
            state: REQUEST_STATE.NEEDTOCHECKITS,
          });

          if (res.data !== undefined && res.data !== null && res.data?.result === true) {
            await fetchData();
          }

          waitForResponse = false;
        } else {
          alert('Wait For Update!!');
        }
      }}
    > {msg}</span >
  }

  const yellowTag = (msg, allowClick) => {
    let cursorStyle = allowClick ? "cursor-pointer" : "";

    return <span
      className={`select-none inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-[5px] ${cursorStyle}`}
      onClick={async (e) => {
        if (allowClick && waitForResponse === false) {
          waitForResponse = true;

          let res = await HTTPRequestManager.updateRequestState({
            accessToken: userInfo.accessToken,
            requestId: location.state.id,
            state: REQUEST_STATE.PROCESSING,
          });

          if (res.data !== undefined && res.data !== null && res.data?.result === true) {
            await fetchData();
          }

          waitForResponse = false;
        } else {
          alert('Wait For Update!!');
        }
      }}
    > {msg}</span >
  }

  const blueTag = (msg, allowClick) => {
    let cursorStyle = allowClick ? "cursor-pointer" : "";

    return <span
      className={`inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-[5px] ${cursorStyle}`}
      onClick={async (e) => {
        if (allowClick && waitForResponse === false) {
          waitForResponse = true;

          let res = await HTTPRequestManager.updateRequestState({
            accessToken: userInfo.accessToken,
            requestId: location.state.id,
            state: REQUEST_STATE.REVISE,
          });

          if (res.data !== undefined && res.data !== null && res.data?.result === true) {
            await fetchData();
          }

          waitForResponse = false;
        } else {
          alert('Wait For Update!!');
        }
      }}
    >{msg}</span>
  }

  const greenTag = (msg, allowClick) => {
    let cursorStyle = allowClick ? "cursor-pointer" : "";

    return <span
      className={`select-none inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-[5px] ${cursorStyle}`}
      onClick={async (e) => {
        if (allowClick && waitForResponse === false) {
          waitForResponse = true;

          let res = await HTTPRequestManager.updateRequestState({
            accessToken: userInfo.accessToken,
            requestId: location.state.id,
            state: REQUEST_STATE.COMPLETE,
          });

          if (res.data !== undefined && res.data !== null && res.data?.result === true) {
            await fetchData();
          }

          waitForResponse = false;
        } else {
          alert('Wait For Update!!');
        }
      }}
    >{msg}</span>
  }

  const purpleTag = (msg, allowClick) => {
    let cursorStyle = allowClick ? "cursor-pointer" : "";

    return <span
      className={`select-none inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20 mr-[5px] ${cursorStyle}`}
      onClick={async (e) => {
        if (allowClick && waitForResponse === false) {
          waitForResponse = true;

          let res = await HTTPRequestManager.updateRequestState({
            accessToken: userInfo.accessToken,
            requestId: location.state.id,
            state: REQUEST_STATE.PAUSE,
          });

          if (res.data !== undefined && res.data !== null && res.data?.result === true) {
            await fetchData();
          }

          waitForResponse = false;
        } else {
          alert('Wait For Update!!');
        }
      }}
    >{msg}</span>
  }

  const blackTag = (msg, allowClick) => {
    let cursorStyle = allowClick ? "cursor-pointer" : "";

    return <span
      className={`select-none inline-flex items-center rounded-md bg-[black] px-2 py-1 text-xs font-medium text-[white] ring-1 ring-inset ring-[black] mr-[5px] ${cursorStyle}`}
      onClick={async (e) => {
        if (allowClick && waitForResponse === false) {
          waitForResponse = true;

          let res = await HTTPRequestManager.updateRequestState({
            accessToken: userInfo.accessToken,
            requestId: location.state.id,
            state: REQUEST_STATE.REJECT,
          });

          if (res.data !== undefined && res.data !== null && res.data?.result === true) {
            await fetchData();
          }

          waitForResponse = false;
        } else {
          alert('Wait For Update!!');
        }
      }}
    >{msg}</span>
  }

  function getComponentState(state) {
    switch (state) {
      case REQUEST_STATE.NEEDTOCHECK:
        return redTag("Need to Check", false);
      case REQUEST_STATE.NEEDTOCHECKITS:
        return checkITSTag("NEED TO CHECK(ITS)", false);
      case REQUEST_STATE.PROCESSING:
        return yellowTag("Processing", false);
      case REQUEST_STATE.COMPLETE:
        return greenTag("Complete", false);
      case REQUEST_STATE.REVISE:
        return blueTag("Revise", false);
      case REQUEST_STATE.PAUSE:
        return purpleTag("Pause", false);
      case REQUEST_STATE.REJECT:
        return blackTag("Reject", false);
    }
  }

  return (
    <div className="relative md:mt-14 p-3 text-left flex flex-col w-full h-full">
      <div className="mb-3">
        {
          (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) && checkITSTag('NEED TO CHECK(ITS)', true)
        }
        {
          (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD || userInfo.rolesId === ROLES.ITS) && blueTag('Revise', true)
        }
        {
          (userInfo.rolesId === ROLES.ITS) && redTag('Remove', true)
        }
        {
          (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) && yellowTag('Processing', true)
        }
        {
          (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) && greenTag('Complete', true)
        }
        {
          (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) && purpleTag('Pause', true)
        }
        {
          (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) && blackTag('Reject', true)
        }
      </div>
      <div className="flex flex-row w-full justify-center mb-5">
        <div
          style={{
            fontSize: 20,
            color: "black",
            cursor: "pointer",
            textAlign: "center",
            marginLeft: 9,
          }}
          onClick={() => { navigate(-1); }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div className="flex w-full justify-center items-center font-bold normal-case">{location.state.title}</div>
        <button className="w-[150px] text-[13px] invisible"
          style={styles.registerButton}
          onClick={async () => {
            onButtonDeleteClick();
          }}
        >
          {languageSet.requestBoardDeleteButton}
        </button>
      </div>
      <div className="h-[2px] bg-[#000]" />
      <div className="flex flex-row w-full bg-[#dedede] mb-[10px] p-2 items-center">
        {
          getComponentState(requestInfo.state)
        }
        <div className="mr-[5px] font-bold">
          [{location.state.id}]
        </div>
        <div className="mr-[10px] font-bold">
          {location.state.userName}
        </div>
        <div className="text-[14px] text-[#000]">
          {location.state.createdAt}
        </div>
      </div>
      <div className="relative flex flex-col gap-1 w-full normal-case mb-[10px] whitespace-pre-wrap bg-[#f7f7f7] py-1 px-2 rounded-lg">
        <div className="font-bold">PERIOD.</div>
        <div>{period?.replaceAll("<br/>", '\n')}</div>
      </div>
      <div className="relative flex flex-col gap-1 w-full normal-case mb-[10px] whitespace-pre-wrap bg-[#f7f7f7] py-1 px-2 rounded-lg">
        <div className="font-bold">AGENT.</div>
        <div>{agent.replaceAll("<br/>", '\n')}</div>
      </div>
      <div className="relative flex flex-col gap-1 w-full normal-case mb-[10px] whitespace-pre-wrap bg-[#f7f7f7] py-1 px-2 rounded-lg">
        <div className="font-bold">FLIGHT SCHEDULE.</div>
        <div>{flight === null ? "TBA" : flight?.replaceAll("<br/>", '\n')}</div>
      </div>
      <div className="relative flex flex-col gap-1 w-full normal-case mb-[10px] whitespace-pre-wrap bg-[#f7f7f7] py-1 px-2 rounded-lg">
        <div className="font-bold">ITINERARY.</div>
        <div>{itinerary.replaceAll("<br/>", '\n')}</div>
      </div>
      <div className="relative flex flex-col gap-1 w-full normal-case mb-[10px] whitespace-pre-wrap bg-[#f7f7f7] py-1 px-2 rounded-lg">
        <div className="font-bold">PAX.</div>
        <div>{pax.replaceAll("<br/>", '\n')}</div>
      </div>
      <div className="relative flex flex-col gap-1 w-full normal-case mb-[10px] whitespace-pre-wrap bg-[#f7f7f7] py-1 px-2 rounded-lg">
        <div className="font-bold">HOTEL STAR RATING.</div>
        <div>{hotelStarRating?.replaceAll("<br/>", '\n')}</div>
      </div>
      {
        (ROLES.GOD === userInfo.rolesId || ROLES.ADMIN === userInfo.rolesId) &&
        <div className="relative flex flex-col gap-1 w-full normal-case mb-[10px] whitespace-pre-wrap bg-[#ffffa0] py-3 px-2 rounded-lg">
          <div className="font-bold flex flex-row w-full justify-between">
            <div>호텔가격.</div>
            <div className="bg-[crimson] text-[white] w-[150px] text-[13px] font-bold px-2 py-1 rounded-sm cursor-pointer select-none text-center"
              onClick={() => {
                setShowInputHotelPrice(true);
              }}
            >입력</div>
          </div>
          <div className="flex flex-col gap-1">
            {
              hotelPriceList?.length > 0 &&
              hotelPriceList.map(item => {
                return (
                  <div
                    className="flex flex-row gap-1 justify-between"
                    key={item.id}>
                    <div className="flex flex-row gap-1 items-center">
                      <div className="cursor-pointer select-none bg-[crimson] text-[white] px-2 text-center rounded-sm font-bold"
                        onClick={async () => {
                          var answer = window.confirm(item?.name + "(" + item?.content + ")" + " 삭제하시겠습니까?");
                          if (answer) {
                            let res = await HTTPRequestManager.removeRequestHotelPrice({
                              id: item.id,
                            });
                            if (res.data?.result) {
                              await getHotelPriceList();
                            }
                          }
                        }}
                      >X</div>
                      <div>{item.name}</div>
                      <div>{item.content}</div>
                    </div>
                    <div>{item.createdAt}</div>
                  </div>
                );
              })
            }
          </div>
        </div>
      }
      <div className="relative flex flex-col gap-1 w-full normal-case mb-[30px] whitespace-pre-wrap bg-[#f7f7f7] py-1 px-2 rounded-lg">
        <div className="font-bold">Schedule.</div>
        <div className="flex w-full flex-wrap">{schedule.replaceAll("<br/>", '\n')}</div>
      </div>
      <div className="flex flex-row w-full normal-case items-center mb-[5px]">
        <div className="flex flex-row w-full text-[13px]">
          <div className="mr-[5px]">comment</div>
          <div className="text-[crimson]">[{commentList.length}]</div>
        </div>
        <button className="w-[150px] text-[13px]"
          style={styles.registerButton}
          onClick={async () => {
            await registerComment();
          }}
        >
          {languageSet.requestBoardRegisterButton}
        </button>
      </div>
      <div className="relative flex w-full mb-[15px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardCommentHint}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setCommentContent(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          value={commentContent}
        />
      </div>
      <div className="m-2">
        <DragDropFile setFile={setFileInfo} />
      </div>
      <div className="flex flex-row w-full normal-case items-center mb-[10px]">
        <div className="flex flex-row w-full text-[13px] invisible">
          <div className="mr-[5px]">comment</div>
          <div className="text-[crimson]">[{commentList.length}]</div>
        </div>
        <button className="w-[150px] text-[13px]"
          style={styles.registerButton}
          onClick={async () => {
            if (fileInfo !== undefined && fileInfo !== "")
              await registerComment(fileInfo);
          }}
        >
          {languageSet.requestBoardFileUploadButton}
        </button>
      </div>
      {
        commentList.map(item => {
          return (
            <RequestCommentItem key={item.id} data={item} refresh={getCommentList} />
          )
        })
      }
      {
        showInputHotelPrice &&
        <RequestBoardHotelPriceDialog
          setShow={setShowInputHotelPrice}
          requestInfo={requestInfo}
          refresh={getHotelPriceList}
        />
      }
    </div>
  );
}