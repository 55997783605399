import React, { useState, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import SideBar from "./SideBar";
import NotFound from "./NotFound";
import Home from "./Home";
import menuItems from "./menuItems";
import DetailTour from "./DetailTour";
import * as HTTPManager from "../features/HTTPManager";
import * as Utils from "../features/Utils";
import PageSettlement from "./infoMenu/PageSettlement";
import ReservationTourList from "./scheduleList/ReservationTourList";
import ReservationTourListEx from "./scheduleList/ReservationTourListEx";
import ReservationHotelList from "./scheduleList/ReservationHotelList";
import ReservationBusList from "./scheduleList/ReservationBusList";
import ReservationGuideList from "./scheduleList/ReservationGuideList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useRecoilValue, useRecoilState } from "recoil";
import { UserInfo, ShowInfo } from "../recoil";
import { useEffect } from "react";
import { ROLES } from "../features/Constant";
import { ToastContainer } from 'react-toastify';
import RequestBoardWriter from "./requestBoard/RequestWriter";
import RequestBoardContent from "./requestBoard/RequestBoardContent";
import RequestFinalWindow from "./requestFinal/RequestFinalWindow";
import { toast } from 'react-toastify';
import SheetJSReactHTML from "./SheetJSReactHTML";

const PRODUCTION = process.env.NODE_ENV === "development" ? false : true;
const SOCKET_URL = PRODUCTION
  ? "wss://ktravelstore.com:1224/"
  : "ws://localhost:1224/";

const BodyBox = ({ menuToggle, setMenuToggle }) => {
  const notify = (msg) => toast(msg);

  HTTPManager.useAxiosInterceptor();

  const body = document.querySelector('body');
  const scrollPosition = window.pageYOffset;

  var sBrowser, sUsrAg = navigator.userAgent;

  const [position, setPosition] = useState(0);

  function onScroll() {
    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edg") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }

  const userInfo = useRecoilValue(UserInfo);
  const [checkAdmin, setCheckAdmin] = useState(false);
  const [showTodoList, setShowTodoList] = useState(false);
  const [todoList, setTodoList] = useState([]);
  const [showInfo, setShowInfo] = useRecoilState(ShowInfo);

  async function fetchData() {
    // let res = await HTTPManager.GetTodoList({
    //   accessToken: userInfo.accessToken,
    // });

    // if (res !== undefined && res.data.result) {
    //   setTodoList(res.data.todoList);
    // }
  }

  const webSocket = useRef(null);

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          setCheckAdmin(false);
          setShowTodoList(false);
        }
        else {
          setCheckAdmin(true);
          fetchData();

          if (webSocket.current === null) {
            // webSocket.current = new WebSocket(SOCKET_URL);

            // webSocket.current.onopen = () => {
            // };

            // webSocket.current.onclose = (error) => {
            //   webSocket.current = null;
            //   webSocket.current = new WebSocket(SOCKET_URL);
            // };

            // webSocket.current.onerror = (error) => {
            // };

            // webSocket.current.onmessage = (event) => {
            //   notify(event.data);
            // };
          }
        }
      })
      .catch((err) => {
        alert(err.message);
      });

    return () => {
      //webSocket.current?.close();
    };
  }, [userInfo, showInfo])

  // zindex 정리
  // top > help info > dim background > 나머지
  const contextClass = {
    success: "bg-gray-600",
    error: "bg-red-600",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "bg-black",
    dark: "bg-white-600 font-gray-300",
  };

  return (
    <div className="flex flex-grow flex-row flex-nowrap h-full">
      {
        showInfo &&
        <div className="fixed h-full w-full top-0 left-0 md:left-0 md:top-14 pl-4 text-sm bg-[#0007] overflow-auto z-[100]" />
      }
      <div className="normal-case">
        <ToastContainer
          position="bottom-right"
          icon={null}
          stacked
          autoClose={false}
          hideProgressBar={true}
          rtl={false}
          toastClassName={
            (context) => contextClass[context?.type || "default"] + " relative flex py-1 px-2 rounded-md justify-between overflow-hidden cursor-pointer mb-1 text-[14px]"
          }

        />
      </div>
      <SideBar menuToggle={menuToggle} setMenuToggle={setMenuToggle} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/detail" element={<DetailTour />} />
        <Route path="/reservationTourList" element={<ReservationTourList />} />
        <Route path="/reservationHotelList" element={<ReservationHotelList />} />
        <Route path="/reservationBusList" element={<ReservationBusList />} />
        <Route path="/reservationTourListEx" element={<ReservationTourListEx />} />
        <Route path="/reservationGuideList" element={<ReservationGuideList />} />
        <Route path="/requestBoardWriter" element={<RequestBoardWriter />} />
        <Route path="/requestBoardContent" element={<RequestBoardContent />} />
        <Route path="/settlementPage" element={<PageSettlement />} />
        <Route path="/requestFinalWindow" element={<RequestFinalWindow />} />
        <Route path="/sheetViewer" element={<SheetJSReactHTML />} />
        {menuItems.map((item, index) => {
          return item.subMenu !== undefined && item.subMenu.length > 0 ? (
            item.subMenu.map((jtem, jndex) => {
              return (
                <Route key={jndex} path={jtem.path} element={jtem.element} />
              );
            })
          ) : (
            <Route key={index} path={item.path} element={item.element} />
          );
        })}
      </Routes>
      {
        // <div className="flex fixed bottom-[50px] right-1 z-[104]">
        //   <span
        //     title="help"
        //     className={"cursor-pointer px-2 py-1 rounded-full mr-2 z-[102] bg-[#22c4f6cc]"}
        //     onClick={() => {
        //       setShowInfo(!showInfo);
        //     }}>
        //     <FontAwesomeIcon icon={faCircleInfo} style={{ color: "white", }} />
        //   </span>
        // </div>
      }
      {
        position > 300 && <div className="flex fixed bottom-3 right-1">
          <span
            title="top"
            className={"cursor-pointer px-[10px] py-1 rounded-full mr-2 z-[99] bg-[#dc143c66] hover:bg-[#dc143caa]"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}>
            <FontAwesomeIcon icon={faArrowUp} style={{ color: "white", }} />
          </span>
        </div>
      }
      {/* {
        checkAdmin && <div className="fixed bottom-3 left-3 text-[20px] cursor-pointer z-[99]" onClick={() => {
          setShowTodoList(!showTodoList);
        }}>
          {
            showTodoList ?
              <FontAwesomeIcon icon={faCircleXmark} style={{ color: "crimson", background: "white", borderRadius: "100%" }} /> :
              <div className="rounded-full bg-[#00000055] text-[white] px-5">{todoList.filter(item => item.complete === 0).length + "/" + todoList.length}</div>
          }
        </div>
      }
      {
        showTodoList && <Window />
      } */}
    </div>
  );
};

export default BodyBox;
