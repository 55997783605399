import React, { useState, useEffect } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import ModifyModal from "./ModifyModal";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../features/Constant";
import UploadDialog from "./hotelList/UploadDialog";
import RegistHotel from "./RegistHotel";
import ColorButton from "./ColorButton";

export default function HotelList() {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(UserInfo);
  const [hotelList, setHotelList] = useState([]);
  const [hotelFilterList, setHotelFilterList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [priceFilterList, setPriceFilterList] = useState([]);

  const [showModify, setShowModify] = useState(false);
  const [modifyValue, setModifyValue] = useState("");
  const [modifyType, setModifyType] = useState("");
  const [modifyHotelId, setModifyHotelId] = useState(0);
  const [modifyHotelPriceId, setModifyHotelPriceId] = useState(0);

  const [selectedHotelInfo, setSelectedHotelInfo] = useState("");
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const [showAddHotel, setShowAddHotel] = useState(false);

  async function fetchData() {
    var response = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setHotelList(response.data.hotelList);
      setHotelFilterList(response.data.hotelList);

      setPriceList(response.data.priceList);
      setPriceFilterList(response.data.priceList);
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  return (
    <div
      className="mt-0 md:mt-14"
      style={{
        padding: 20,
        display: "flex",
        backgroundColor: "#fff",
        width: "100%",
        fontSize: 13,
      }}
    >
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row w-full mb-1">
          <ColorButton title="등록" color="slateblue" handler={() => setShowAddHotel(!showAddHotel)} />
        </div>

        <div className="mb-[10px] font-bold">호텔 목록 ({hotelFilterList?.length})</div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="검색"
            type="text"
            name="pax"
            style={{ flex: 1 }}
            onChange={(e) => {
              let searchList = [];
              searchList = hotelList.filter(item => item.address1?.includes(e.target.value) || item.address2?.includes(e.target.value)
                || item.breakfast?.includes(e.target.value) || item.memo?.includes(e.target.value) || item.name?.includes(e.target.value))
              setHotelFilterList(searchList);
            }}
          />
        </div>
        {
          hotelFilterList.map(hotel => {
            return (
              <div key={hotel.id} className="border-[lightgrey] border-2 mb-1 rounded p-1" style={{ display: 'flex', flexDirection: 'column', }}>
                <div key={hotel.id} style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: 10, }}>{hotel.id}.</div>
                  <div className="select-none cursor-pointer mr-[5px]"
                    onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("address1");
                      setModifyValue(hotel.address1);
                      setShowModify(true);
                    }}>
                    {hotel.address1} ||
                  </div>
                  <div className="select-none cursor-pointer mr-[5px]"
                    onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("address2");
                      setModifyValue(hotel.address2);
                      setShowModify(true);
                    }}>
                    {hotel.address2} ||
                  </div>
                  <div className="select-none cursor-pointer mr-[5px]"
                    onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("name");
                      setModifyValue(hotel.name);
                      setShowModify(true);
                    }}>
                    <div className="text-[deepskyblue]">{hotel.name}</div>
                  </div>
                  <div className="mr-[5px]">||</div>
                  <div className="select-none cursor-pointer mr-[5px]"
                    onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("nameEN");
                      setModifyValue(hotel.nameEN);
                      setShowModify(true);
                    }}>
                    {
                      (hotel.nameEN !== undefined && hotel.nameEN !== null) ?
                        <div className="text-[deepskyblue]">{hotel.nameEN}</div> :
                        <div className="text-[crimson]">[영어이름등록]</div>
                    }
                  </div>
                  <div className="mr-[5px]">||</div>
                  <div className="select-none cursor-pointer mr-[5px]"
                    onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("breakfast");
                      setModifyValue(hotel.breakfast);
                      setShowModify(true);
                    }}>
                    조식 {hotel.breakfast} ||
                  </div>
                  <div className="flex flex-row md:flex-col">
                    {
                      priceFilterList?.map((price, index) => {
                        if (price.hotelId === hotel.id) {
                          return (
                            <div key={index} className="select-none cursor-pointer"
                              style={{ display: 'flex', flexDirection: 'row' }}>
                              <div style={{ marginRight: 5 }}
                                onClick={() => {
                                  setModifyHotelId(hotel.id);
                                  setModifyHotelPriceId(price.id);
                                  setModifyType("type");
                                  setModifyValue(price.type);
                                  setShowModify(true);
                                }}>
                                {price.type}
                              </div>
                              <div style={{ marginRight: 5 }}
                                onClick={() => {
                                  setModifyHotelId(hotel.id);
                                  setModifyHotelPriceId(price.id);
                                  setModifyType("price");
                                  setModifyValue(price.price);
                                  setShowModify(true);
                                }}>
                                {price.price} ||
                              </div>
                            </div>
                          );
                        } else return null
                      })
                    }
                  </div>
                </div>
                {
                  hotel.memo.length > 0 &&
                  <div style={{ marginRight: 0, color: "red", }}
                    onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("memo");
                      setModifyValue(hotel.memo);
                      setShowModify(true);
                    }}>
                    ※ {hotel.memo}
                  </div>
                }
                <div className="mb-1" />
                <div className="flex flex-row md:flex-col justify-end">
                  <div className="flex flex-row">
                    <div className="rounded text-[white] px-1 select-none cursor-pointer mr-1 md:mr-0 md:mb-1"
                      style={{
                        background: (hotel.priceFile !== undefined && hotel.priceFile !== null) ? "black" : "grey"
                      }}
                      onClick={() => {
                        Utils.showContent(hotel.priceFile)
                      }}>파일보기</div>
                    {
                      hotel.modifyAt &&
                      <div className="rounded px-1 select-none cursor-pointer mr-1 md:mr-0">({hotel.modifyAt?.slice(2, 19)})</div>
                    }
                  </div>
                  <div className="rounded bg-[black] text-[white] px-1 select-none cursor-pointer mr-1 md:mr-0 md:mb-1" onClick={() => {
                    setSelectedHotelInfo(hotel);
                    setModifyValue(`${hotel.id} ${hotel.address1} ${hotel.address2} ${hotel.name}`);
                    setShowUploadDialog(true);
                  }}>
                    파일업로드
                  </div>
                  <div className="rounded bg-[black] text-[white] px-1 select-none cursor-pointer md:mb-1"
                    onClick={async () => {
                      let answer = window.confirm(
                        "파일을 삭제 하시겠습니까?"
                      );

                      if (answer) {
                        let res = await HTTPManager.DeleteFileHotelPrice({
                          accessToken: userInfo.accessToken,
                          id: hotel.id,
                          type: "priceFile",
                        });

                        if (res.data !== undefined && res.data.result) {
                          await fetchData();
                        }
                      } else {
                      }
                    }}>
                    파일삭제
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
      <ModifyModal show={showModify} setShow={setShowModify} value={modifyValue} setValue={setModifyValue} onClick={async () => {
        let res = await HTTPManager.UpdateHotelInfo({
          accessToken: userInfo.accessToken,
          hotelId: modifyHotelId,
          hotelPriceId: modifyHotelPriceId,
          value: modifyValue,
          type: modifyType,
        })

        if (res?.data?.result) {
          await fetchData();
        } else {
          alert("변경실패!");
        }

        setShowModify(false);
      }} />
      {
        showUploadDialog &&
        <UploadDialog
          title={modifyValue}
          setShow={setShowUploadDialog}
          data={selectedHotelInfo}
          refresh={fetchData}
          type={"priceFile"}
        />
      }
      <div className="fixed flex flex-col w-full md:top-14">
        {
          showAddHotel &&
          <>
            <div className="flex w-full h-full text-center">
              <RegistHotel setShow={setShowAddHotel} refresh={fetchData} />
            </div>
          </>
        }
      </div>
    </div>
  );
}