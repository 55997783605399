import axios from "axios";
import { ERRORCODE } from "../features/Constant";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UserInfo } from "../recoil";
import { useNavigate } from "react-router-dom";

const PRODUCTION = process.env.NODE_ENV === "development" ? false : true;

const SERVER_URL = PRODUCTION
  ? "https://ktravelstore.com:1225"
  : "http://localhost:1225";

const instance = axios.create({
  baseURL: SERVER_URL,
  withCredentials: true,
});

export const useAxiosInterceptor = () => {
  const setMyInfo = useSetRecoilState(UserInfo);
  const navigate = useNavigate();
  const [needToLogin, setNeedToLogin] = useState(false);

  const errorHandler = (error) => {
    //let msg = error.data.message;
    let code = error.data.code;

    switch (code) {
      // refresh 토큰까지 만료된 경우 > 재로그인 요청
      case ERRORCODE.NEED_TO_LOGIN.CODE:
        setNeedToLogin(true);
        setMyInfo({});
        break;
      default:
        break;
    }

    return error;
  };

  const responseHandler = (response) => {
    return response;
  };

  const responseInterceptor = instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error.response)
  );

  useEffect(() => {
    // 로그인 만료 처리
    if (needToLogin) {
      setNeedToLogin(false);
      navigate("/", { replace: true });
    }

    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [responseInterceptor, needToLogin]);
};

export async function getLogList(Props) {
  return await instance
    .get("/log", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}
