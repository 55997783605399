const Locale = {
  // TopBar
  signin: "Log in",
  signout: "Logout",
  changePw: "Change password",
  changeENName: "Change english name",

  // SideBar
  menuInfo: "Info",
  menuInfoSchedule: "Schedule",
  menuInfoGuideSettlement: "Settlement",

  menuManagement: "Management",
  menuManagementIncome: "Income",
  menuManagementSettlement: "정산",
  menuManagementProfitAndLoss: "Profit, Loss",
  menuManagementInvoice: "Invoice",
  menuManagementAgencyAccount: "Agency Account",

  menuHanmaru: "한마루",
  menuHanmaruAccount: "매입 매출",
  menuHanmaruFinish: "마감",

  menuTour: "Tour",
  menuTourCalendar: "Calendar",
  menuTourList: "List",
  menuTourListEx: "ListEx",
  menuTourCreate: "Create Tour",
  menuTourRooming: "Rooming",
  menuTourRequest: "Request Board",
  menuTourStatistics: "Statistics",

  menuUser: "User",
  menuGuide: "Guide",
  menuManager: "Manager",
  menuGuideRegistNList: "Regist And List",

  menuBus: "Bus",
  menuHotel: "Hotel",
  menuBusRegistNList: "Regist And List",
  menuBusCalendar: "Calendar",
  menuHotelRegistNList: "Regist And List",

  menuAttraction: "Attraction",
  menuAttractionList: "Attraction List",

  menuCommunity: "Community",
  menuCommunityMiniGame: "Mini Game",
  menuCommunityLunch: "Today Lunch",

  menuManagerSetting: "Manager Setting",

  menuLog: "Log",

  // 로그인 창
  signinTitle: "LOG IN",
  signinIdHint: "ID",
  signinPwHint: "PASSWORD",
  signinAuto: "Auto Log in",
  signinCancel: "Close",

  // 비밀번호 변경
  pwChangeTitle: "Change Password",
  pwChangeHint1: "Password",
  pwChangeHint2: "Password Confirm",
  pwChangePass: "Pass",
  pwChangeFail: "Check Password",
  pwChangeClose: "Cancel",
  pwChangeOk: "Change",

  // 루밍 페이지
  roomingTitle: "Rooming Information",
  roomingAddListButton: "Add List",
  roomingFindHint: "Search",

  // 루밍 페이지 목록 추가 팝업
  roomingAddListDialogDescription: "Input (Separated By The Enter)",
  roomingAddListDialogInputHint: "ex) 27jan sorak seoul 5d4n 15+1 asiana avenir NO",
  roomingAddListDialogConfirmButton: "Regist",

  // 루밍 목록 아이템
  roomingItemCreateDate: "created",
  roomingItemModifyDate: "modify",
  roomingItemButton: "ROOMING",
  roomingItemfinalDocumentButton: "FINAL DOCUMENT (KTS)",
  roomingItemfinalDocumentITSButton: "FINAL DOCUMENT (ITS)",
  roomingItemInvoiceButton: "INVOICE",
  roomingItemLogoButton: "LOGO",

  // 요청사항 게시판
  requestBoardTitle: "Request",
  requestBoardWriter: "Write Request",
  requestBoardHeaderNumber: "No",
  requestBoardHeaderTitle: "Title",
  requestBoardHeaderTitleHint: "* Write Title",
  requestBoardHeaderContent: "Content",
  requestBoardHeaderContentHint: "Write Content",
  requestBoardHeaderAccount: "Account",
  requestBoardHeaderDate: "Date",
  requestBoardAllButton: "ALL",
  requestBoardWriteButton: "Write",
  requestBoardRegisterButton: "Register",
  requestBoardFindHint: "Search",
  requestBoardDeleteButton: "Delete",
  requestBoardCommentHint: "Write Comment",
  requestBoardFileUploadButton: "Upload",
  requestBoardPeriod: "* Period ex) 01-07 MAR",
  requestBoardAgent: "* Agent ex) ITS, WIDARIN, AVENIR",
  requestBoardFlightSchedule: "Flight Schedule ex) OZ, GA, BI, SQ",
  requestBoardItinerary: "* Itinerary ex) SORAK-SEOUL",
  requestBoardPax: "* Pax ex) 25+1",
  requestBoardHotelStarRating: "* Hotel Star Rating ex) SEOUL 3 star, BUSAN 4 star, JEJU 3 star",
  requestBoardSchedule: "* Schedule ex) GYEONGBOK PALACE & NATIONAL MUSEUM, NAMI ISLAND",

  // 투어생성 Dialog
  createTourTitle: "Create Tour",
  createTourHint: "dd-dd MM document Infomation\ndd-dd MM document Infomation",
  createTourOk: "Create",

  // 공통
  strCancel: "Cancel",
  strUpdate: "Update",
}

export default Locale;