import React, { useEffect, useState, useMemo, useRef } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPHanmaruManager from "../../features/HTTPHanmaruManager";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../styles/CommonStyle.css";
import CalendarMovingBox from "./CalendarMovingBox";
import AccountItem from "./AccountItem";

export default function AccountPage() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [accountList, setAccountList] = useState([]);
  const [accountFilterList, setAccountFilterList] = useState([]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);


  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth() + 1);
  }

  async function fetchData() {
    let res = await HTTPHanmaruManager.GetAccountList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      let tmpList = res.data.list.sort((a, b) => {
        if (new Date(a.date.replace(" ", "T")) < new Date(b.date.replace(" ", "T")))
          return -1;
        if (new Date(a.date.replace(" ", "T")) > new Date(b.date.replace(" ", "T")))
          return 1;
      });

      setAccountList(tmpList);
      setAccountFilterList(tmpList);
    }
  }

  function clearList() {
    setAccountFilterList(accountList);
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.GOD, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
          await initalize();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  return (
    <div className={`w-full h-full`}>
      <div className="w-full h-full md:mt-14">
        <div className="flex flex-col w-full h-full gap-1 pl-3 md:pl-1">
          <CalendarMovingBox year={selectedYear} setYear={setSelectedYear} month={selectedMonth} setMonth={setSelectedMonth} clear={clearList} />
          <div className="scrollRemoveBox flex mb-3">
            <span
              title="전체()"
              className="select-none flex-none items-center rounded-md bg-black px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-[black] cursor-pointer hover:bg-[gray] hover:ring-[gray]"
              onClick={() => {
                let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25));
                setAccountFilterList(tmpList);
              }}>
              전체({accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25))?.length})
            </span>
            <span
              title="매입미분류"
              className="select-none flex-none items-center rounded-md bg-black px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-[black] cursor-pointer hover:bg-[gray] hover:ring-[gray] ml-1"
              onClick={() => {
                let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0 && item.contentType === null);
                setAccountFilterList(tmpList);
              }}>
              매입미분류({accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0 && item.contentType === null)?.length})
            </span>
            <span
              title="매출"
              className="select-none flex-none items-center rounded-md bg-black px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-[black] cursor-pointer hover:bg-[gray] hover:ring-[gray] ml-1"
              onClick={() => {
                let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 1);
                setAccountFilterList(tmpList);
              }}>
              매출({accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 1)?.length})
            </span>
            <span
              title="매입"
              className="select-none flex-none items-center rounded-md bg-black px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-[black] cursor-pointer hover:bg-[gray] hover:ring-[gray] ml-1"
              onClick={() => {
                let tmpList = accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0);
                setAccountFilterList(tmpList);
              }}>
              매입({accountList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25) && item.type === 0)?.length})
            </span>
            <span
              title="복사"
              className="select-none flex-none items-center rounded-md bg-black px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-[black] cursor-pointer hover:bg-[gray] hover:ring-[gray] ml-1"
              onClick={() => {
                navigator.clipboard.writeText(`${accountFilterList.map(item => {
                  return item.date + "/" + item.content.replaceAll('/', '') + "/" + item.amount + "/" + item.contentType + "\n";
                })}`);
              }}>
              복사
            </span>
          </div>
          <div className="flex flex-col w-full overflow-auto gap-2">
            {
              accountFilterList.filter(item => new Date(item.date.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                && new Date(item.date.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25)).reverse().map((item, index) => {
                  return (
                    <AccountItem account={item} index={index} refresh={fetchData} key={item.id} />
                  )
                })
            }
          </div>
          <div className="flex flex-row gap-1">
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}