import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import * as Utils from "../../features/Utils";
import * as HTTPManager from "../../features/HTTPManager";
import { UserInfo } from "../../recoil";
import TableIncome from "./TableIncome";
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";

export default function CalculateIncome() {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(UserInfo);
  const [tourList, setTourList] = useState([]);

  const minWidth = 100;

  const columns = useMemo(
    () => [
      {
        accessor: "id",
        Header: "번호",
        minWidth: 50,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 0,
      },
      {
        accessor: "startDate",
        Header: "시작일",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 1,
      },
      {
        accessor: "endDate",
        Header: "종료일",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 2,
      },
      {
        accessor: "guide",
        Header: "가이드",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 3,
      },
      {
        accessor: "ginseng",
        Header: "청하",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 4,
        needCalculate: true,
      },
      {
        accessor: "redpine",
        Header: "적송",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 5,
        needCalculate: true,
      },
      {
        accessor: "bogan",
        Header: "보간영",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 6,
        needCalculate: true,
      },
      {
        accessor: "cc",
        Header: "참앤참",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 7,
        needCalculate: true,
      },
      {
        accessor: "amethyst",
        Header: "자수정",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 8,
        needCalculate: true,
      },
      {
        accessor: "chunghae",
        Header: "청해초",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 9,
        needCalculate: true,
      },
      {
        accessor: "songhak",
        Header: "송학",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 10,
        needCalculate: true,
      },
      {
        accessor: "superMarket",
        Header: "명품",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 11,
        needCalculate: true,
      },
      {
        accessor: "poonglim",
        Header: "풍림",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "right",
          paddingRight: 10,
          fontSize: 13,
          cursor: "pointer",
        },
        index: 12,
        needCalculate: true,
      },
    ],
    []
  );

  // 서버 데이터 수신
  async function fetchData() {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          let res = await HTTPManager.GetUserList({
            accessToken: userInfo.accessToken,
          });

          let userList = [];
          let toursGuideInfo = [];
          let toursIncomeInfo = [];

          if (res !== undefined) {
            userList = res.data.result;
          }

          res = await HTTPManager.GetTourReservation({
            accessToken: userInfo.accessToken,
          });

          if (res !== undefined && res.data !== undefined) {
            toursGuideInfo = res.data.toursGuide;
            toursIncomeInfo = res.data.toursIncome;
          }

          res = await HTTPManager.GetTourList({
            accessToken: userInfo.accessToken
          })

          if (res !== undefined) {
            let tourList = res.data.result.sort((a, b) => new Date(a.startDate.replace(" ", "T")) - new Date(b.startDate.replace(" ", "T")))

            setTourList(tourList.map(tour => {
              let guideId = toursGuideInfo.find(tourGuide => tourGuide.tourId === tour.id)?.guideId;
              let guideName = userList.find(user => user.id === guideId)?.name;
              let tourIncomeInfo = toursIncomeInfo.find(incomeInfo => incomeInfo.tourId === tour.id);

              return ({
                id: tour.id,
                startDate: tour.startDate.slice(0, 10),
                endDate: tour.endDate.slice(0, 10),
                guide: guideName,
                ginseng: tourIncomeInfo !== undefined ? tourIncomeInfo.ginseng : "",
                redpine: tourIncomeInfo !== undefined ? tourIncomeInfo.redpine : "",
                bogan: tourIncomeInfo !== undefined ? tourIncomeInfo.bogan : "",
                cc: tourIncomeInfo !== undefined ? tourIncomeInfo.cc : "",
                amethyst: tourIncomeInfo !== undefined ? tourIncomeInfo.amethyst : "",
                chunghae: tourIncomeInfo !== undefined ? tourIncomeInfo.chunghae : "",
                songhak: tourIncomeInfo !== undefined ? tourIncomeInfo.songhak : "",
                superMarket: tourIncomeInfo !== undefined ? tourIncomeInfo.superMarket : "",
                poonglim: tourIncomeInfo !== undefined ? tourIncomeInfo.poonglim : "",
                cancel: tour.cancel,
              });
            }));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const data = useMemo(() => tourList, [tourList]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className="h-full mt-0 md:mt-14"
      style={{
        padding: 20,
      }}
    >
      {tourList?.length > 0 && <TableIncome className="-striped -highlight" columns={columns} data={data} style={{ borderStyle: "normal" }} refresh={fetchData} />}
    </div>
  );
}