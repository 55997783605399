import React, { useState, useEffect } from "react";
import CurrentSchedule from "./home/myschedules/CurrentSchedule";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../recoil";
import NoticeBoard from "./home/NoticeBoard";

const Home = () => {
  const today = new Date();
  const userInfo = useRecoilValue(UserInfo);

  useEffect(() => {

  }, [userInfo]);

  return (
    <div className="flex p-4 bg-[white] flex-1 md:mt-14 flex-row md:flex-col md:gap-2">
      <NoticeBoard />
      <CurrentSchedule />
    </div>
  );
};

export default Home;
