import React, { useState, useEffect } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../features/Constant";
import RegistBusCompany from "./RegistBusCompany";
import ColorButton from "./ColorButton";

export default function () {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const [busList, setBusList] = useState([]);
  const [busFilterList, setBusFilterList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [priceFilterList, setPriceFilterList] = useState([]);

  const [showAddBus, setShowAddBus] = useState(false);

  async function fetchData() {
    var response = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setBusList(response.data.busList);
      setBusFilterList(response.data.busList);

      setPriceList(response.data.priceList);
      setPriceFilterList(response.data.priceList);
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  return (
    <div
      className="md:mt-14"
      style={{
        padding: 20,
        width: "100%",
        flex: 1,
        backgroundColor: "#fff",
        fontSize: 13,
      }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <div className="flex flex-row w-full mb-1">
          <ColorButton title="등록" color="slateblue" handler={() => setShowAddBus(!showAddBus)} />
        </div>

        <div className="mb-1 font-bold">버스 목록 ({busFilterList?.length})</div>
        {
          busFilterList?.map(item => {
            return (
              <div key={item.id} style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10 }}>{item.id}</div>
                <div style={{ marginRight: 10 }}>{item.name}</div>
                <div style={{ marginRight: 10 }}>{item.area}</div>
                <div style={{ marginRight: 10 }}>{item.manager}</div>
                {
                  priceFilterList?.map((price, index) => {
                    if (price.busId === item.id) {
                      return (
                        <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>

                        </div>
                      );
                    } else return null
                  })
                }
              </div>
            );
          })
        }
      </div>
      <div className="fixed flex flex-col w-full md:top-14">
        {
          showAddBus &&
          <>
            <div className="flex w-full h-full text-center">
              <RegistBusCompany setShow={setShowAddBus} refresh={fetchData} />
            </div>
          </>
        }
      </div>
    </div >
  );
}