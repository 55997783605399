import React, { useState, useEffect, forwardRef } from "react";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import * as HTTPRequestManager from "../../features/HTTPRequestManager";

const styles = {
  enabledButton: {
    fontWeight: "bold",
    outline: "none",
    background: "#000",
    color: "#fff",
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 7,
    paddingLeft: 7,
  },
  disabledButton: {
    fontWeight: "bold",
    outline: "none",
    background: "grey",
    color: "#fff",
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 7,
    paddingLeft: 7,
  },
};

export default function RequestBoardWriter(Props) {
  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);

  // 전송 데이터
  const [title, setTitle] = useState("");

  const [boardPeriod, setBoardPeriod] = useState("");
  const [boardAgent, setBoardAgent] = useState("");
  const [boardFlightSchedule, setBoardFlightSchedule] = useState("");
  const [boardItinerary, setBoardItinerary] = useState("");
  const [boardPax, setBoardPax] = useState("");
  const [boardHotelStarRating, setHotelStarRating] = useState("");
  const [boardSchedule, setBoardSchedule] = useState("");

  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const userInfo = useRecoilValue(UserInfo);

  useEffect(() => {
    checkInput();
  }, [title, boardPeriod, boardAgent, boardItinerary, boardPax, boardHotelStarRating, boardSchedule, isButtonDisabled]);

  async function checkInput() {
    if (title.length > 0 && boardAgent.length > 0 && boardItinerary.length > 0 && boardPax.length > 0 && boardSchedule.length > 0 && boardPeriod.length > 0 && boardHotelStarRating.length > 0) {
      enableButton();
    } else {
      disableButton();
    }
  }

  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };

  async function onButtonClick() {
    var res = await HTTPRequestManager.insertRequest({
      accessToken: userInfo.accessToken,
      title: title,
      period: boardPeriod,
      agent: boardAgent,
      flight: boardFlightSchedule,
      itinerary: boardItinerary,
      pax: boardPax,
      hotelStarRating: boardHotelStarRating,
      schedule: boardSchedule.replaceAll("\n", "<br/>"),
    });

    if (res.data.result) {
      navigate(-1);
    } else {
      alert("등록 실패");
    }
  }

  return (
    <div className="md:mt-14 p-3 text-left flex flex-col w-full">
      <div className="flex flex-row w-full justify-center mb-5 ">
        <div
          style={{
            fontSize: 20,
            color: "black",
            cursor: "pointer",
            textAlign: "center",
            marginLeft: 9,
          }}
          onClick={() => { navigate(-1); }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div className="flex w-full justify-center items-center font-bold">{languageSet.requestBoardWriter}</div>
        <button className="w-[150px] text-[13px]"
          style={isButtonDisabled ? styles.disabledButton : styles.enabledButton}
          disabled={isButtonDisabled}
          onClick={async () => {
            onButtonClick();
          }}
        >
          {languageSet.requestBoardRegisterButton}
        </button>
      </div>
      <div className="relative flex w-full mb-[5px] pl-[5px] normal-case text-[red]">
        * : mandatory
      </div>
      <div className="relative flex w-full mb-[15px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardHeaderTitleHint}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          value={title}
        />
      </div>
      <div className="relative flex w-full mb-[5px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardPeriod}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setBoardPeriod(e.target.value);
          }}
          value={boardPeriod}
        />
      </div>
      <div className="relative flex w-full mb-[5px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardAgent}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setBoardAgent(e.target.value);
          }}
          value={boardAgent}
        />
      </div>
      <div className="relative flex w-full mb-[5px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardFlightSchedule}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setBoardFlightSchedule(e.target.value);
          }}
          value={boardFlightSchedule}
        />
      </div>
      <div className="relative flex w-full mb-[5px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardItinerary}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setBoardItinerary(e.target.value);
          }}
          value={boardItinerary}
        />
      </div>
      <div className="relative flex w-full mb-[5px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardPax}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setBoardPax(e.target.value);
          }}
          value={boardPax}
        />
      </div>
      <div className="relative flex w-full mb-[5px]">
        <input
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardHotelStarRating}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setHotelStarRating(e.target.value);
          }}
          value={boardHotelStarRating}
        />
      </div>
      <div className="relative flex w-full mb-[5px]">
        <textarea
          rows="10"
          className="placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
          placeholder={languageSet.requestBoardSchedule}
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setBoardSchedule(e.target.value);
          }}
          value={boardSchedule}
        />
      </div>
    </div>
  );
}