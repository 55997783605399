import React, { useEffect, useState, useMemo, useRef } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPManager from "../../features/HTTPManager";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../styles/CommonStyle.css";
import { Tooltip } from "react-tooltip";

export default function FundItem(Props) {
  let tour = Props.tour;
  let index = Props.index;

  function getFundState(state) {
    let result = "";
    switch (state) {
      case 0:
        result = "신청";
        break;
      case 1:
        result = "지원금 확정";
        break;
      case 2:
        result = "결과보고완료";
        break;
      case 3:
        result = "지급확정";
        break;
    }

    return result;
  }

  function getFundProofState(state) {
    let result = [];
    switch (state) {
      case null:
      case 0:
        result.push(
          <div className="state0 h-4 w-4 bg-red-500 rounded-full cursor-pointer" key={state}>
            <Tooltip className={`z-[999]`} anchorSelect={`.state0`} place="top">확인필요</Tooltip>
          </div>);
        break;
      case 1:
        result.push(
          <div className="state1 h-4 w-4 bg-green-500 rounded-full cursor-pointer" key={state}>
            <Tooltip className={`z-[999]`} anchorSelect={`.state1`} place="top">확인완료</Tooltip>
          </div>);
        break;
    }

    return result;
  }

  return (
    <div className="flex flex-row items-center text-center gap-1" key={tour.id}>
      <div>[{Utils.formatNumberDigit(index + 1)}] </div>
      <div className="flex font-bold" style={{
        color: tour.fundGroupType === 1 ? "crimson" : "deepskyblue"
      }}>[{tour.fundGroupType === 0 ? "패키지" : "인센티브"}]</div>
      <div>{tour.id} </div>
      <div>{tour.country} </div>
      <div>{tour.fundCity === 1 ? "강원" : ""}</div>
      <div>{tour.startDate.slice(2, 10)} ~ {tour.endDate.slice(2, 10)} </div>
      <div>{tour.guide} </div>
      <div>[{getFundState(tour.fundState)}]</div>
      <div className="flex items-center bg-[#efefef] py-1 px-2 rounded gap-1">정산서 {getFundProofState(tour.fundAccount)}</div>
      <div className="flex items-center bg-[#efefef] py-1 px-2 rounded gap-1">영수증 {getFundProofState(tour.funcReceipt)}</div>
      {
        tour.fundPicture !== undefined && tour.fundPicture !== null ? <div className="flex items-center bg-[#efefef] py-1 px-2 rounded gap-1">사진 {getFundProofState(tour.fundPicture)}</div> : null
      }
      <div className="flex font-bold">{Utils.formatNumber(tour.fundAmount)}원</div>
      <div className="flex font-bold text-[crimson]">{(tour.fundDepositDate !== undefined && tour.fundDepositDate !== null) && tour.fundDepositDate?.slice(0, 10) + " 지급"}</div>
    </div>
  )
}