import React, { useState } from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import * as Utils from "../../features/Utils";
import * as HTTPManager from "../../features/HTTPManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import ColorButton from "../ColorButton";

export default function TableIncome({ columns, data, refresh }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const [changeTourId, setChangeTourId] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [inputHint, setInputHint] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputType, setInputType] = useState("");

  const userInfo = useRecoilValue(UserInfo);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      if (inputValue.length === 0) {
        alert("빈칸확인");
        return;
      }

      let res = await HTTPManager.ChangeShopIncome({
        accessToken: userInfo.accessToken,
        tourId: changeTourId,
        type: inputType,
        value: inputValue,
      });

      if (res?.data?.result) {
        setShowInput(false);
        alert("수정완료");
        refresh();
      } else {
        setShowInput(false);
        alert("수정실패");
      }
    } else if (event.keyCode === 27) {
      setShowInput(false);
    }
  }

  return (
    <>
      <form onSubmit={(event) => {
        event.preventDefault();
        setGlobalFilter(event.target.elements.search.value);
      }}
        style={{ marginBottom: 10 }} >
        <input
          name="search"
          className="border-[1px] mr-[10px] rounded p-1"
          placeholder="검색"
        />
        <button>현재 {rows.length}개</button>
      </form>
      <table {...getTableProps()}>
        <thead>
          {
            headerGroups.map((headerGroup) => {
              return (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      border: "1px solid rgb(0, 0, 0)",
                      fontSize: 13,
                      position: "sticky",
                      top: 0,
                      background: "skyblue",
                      color: "white",
                    }}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              )
            })
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map((row) => {
              prepareRow(row);

              let tourInfo = data.find(tour => tour.id === row.cells.at(0).value);

              // 취소여부
              let isCanceled = tourInfo?.cancel === 1;

              return (
                <tr {...row.getRowProps()} key={row.index} style={{
                  background: isCanceled ? "#f004" : (row.index % 2 === 0 ? "#ddd7" : "#fff"),
                }}>
                  {row.cells.map((cell) => {
                    let calculateValue = cell.column.needCalculate && Math.floor(Utils.calculateString(cell.value));

                    return (
                      <td key={cell.row.id + " " + cell.column.index}
                        {...cell.getCellProps({
                          style: cell.column.style,
                        })}

                        onClick={async () => {
                          if (cell.column.id !== "id" && cell.column.id !== "startDate"
                            && cell.column.id !== "endDate" && cell.column.id !== "guide"
                            && cell.column.id !== "income") {
                            setShowInput(true);
                            setInputType(cell.column.id);
                            setInputHint(cell.column.Header);

                            if (cell.value === null || cell.value === undefined || cell.value.length === 0)
                              setInputValue("");
                            else {
                              // 원본값 표시 구현 필요
                              setInputValue(cell.value);
                            }

                            setChangeTourId(row.cells.at(0).value);
                          }
                        }}
                      >
                        {
                          (cell.column.needCalculate ? cell.value === "0" ? "-" : Utils.formatNumber(calculateValue) : cell.render("Cell"))
                        }
                      </td>
                    )
                  })}
                </tr>
              );
            })
          }
        </tbody>
        <tfoot>
          {
            footerGroups.map((footerGroup, index) => {
              return (
                <tr key={index}>
                  {
                    footerGroup.headers.map((column) => {
                      let value = column.index >= 4 ? rows.reduce((a, b) => a = a + Math.floor(Utils.calculateString(b.cells[column.index].value?.replace(/,/g, ""))), 0) : "";
                      let color = value >= 0 ? "blue" : "red";

                      if (column.index === 3) {
                        return (
                          <td
                            colSpan="4"
                            key={column.id}
                            style={{
                              border: "1px solid rgb(0, 0, 0)",
                              fontSize: 14,
                              textAlign: "center",
                              fontWeight: "bold",
                              paddingRight: 10,
                            }} >
                            합계
                          </td>
                        );
                      } else if (column.index > 3) {
                        return (
                          <td key={column.id} {...column.getHeaderProps()} style={{
                            border: "1px solid rgb(0, 0, 0)",
                            fontSize: 14,
                            textAlign: "right",
                            color: color,
                            fontWeight: "bold",
                            paddingRight: 10,
                          }} >
                            {Utils.formatNumber(value)}
                          </td>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
                </tr>
              )
            })
          }
        </tfoot>
      </table>
      {
        // 수정 입력창 다이얼로그
        showInput &&
        <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
          <div style={{ display: "flex", flexDirection: "column", width: "80%", background: "#000a", padding: 20, paddingTop: 20, borderRadius: 10 }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              <div className="w-[25px] h-[25px] text-[15px] text-[white] cursor-pointer text-center"
                onClick={() => { setShowInput(false) }}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                placeholder={inputHint}
                type="text"
                name="input"
                style={{ width: "100%", marginBottom: 10, }}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <div className="text-[white] mb-2 text-center w-full normal-case font-bold">계산식 저장 가능 ex) 12000 * 1.1</div>
              <div className="text-[white] mb-2 text-center w-full  font-bold">※ 주의 : 소수점 이하 버림 처리</div>
              <ColorButton title={"변경"} color={"deepskyblue"} width={"100%"} handler={async () => {
                if (inputValue.length === 0) {
                  alert("빈칸확인");
                  return;
                }

                let res = await HTTPManager.ChangeShopIncome({
                  accessToken: userInfo.accessToken,
                  tourId: changeTourId,
                  type: inputType,
                  value: inputValue,
                });

                if (res?.data?.result) {
                  setShowInput(false);
                  alert("수정완료");
                  refresh();
                } else {
                  setShowInput(false);
                  alert("수정실패");
                }
              }} />
            </div>
          </div>
        </div>
      }
    </>
  );
}